import { Routes } from '@angular/router';

export const CommonLayout_ROUTES: Routes = [
    {
        path: 'dashboard',
        loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule),
    },
    {
        path: 'master',
        loadChildren: () => import('../../master-data/master-data.module').then(m => m.MasterDataModule),
    } ,
    {
        path: 'expense',
        loadChildren: () => import('../../expense-data/expense-data.module').then(m => m.ExpenseDataModule),
    }  ,
    {
        path: 'policy',
        loadChildren: () => import('../../policy-data/policy-data.module').then(m => m.PolicyDataModule),
    } 
];